<!--
 * @Author: Neko
 * @Date: 2021-01-07 09:39:52
 * @LastEditTime: 2021-03-25 21:09:54
 * @LastEditors: Neko
-->
<template>
  <div class="order__container">
    <div class="order__header">
      <ul class="list">
        <li
          v-for="(item, index) in tabList"
          :key="item.name"
          class="item"
          :class="{ small: index === 0 }"
          @click="onClickTabItem(item, index)"
        >
          <a href="javascript:void(0)" class="link" :class="{ active: active === index }">
            {{ item.name }}（{{ item.number }}）
          </a>
        </li>
      </ul>
    </div>

    <div class="order__body">
      <el-table :data="tableData" header-row-class-name="header-color" row-class-name="body-color">
        <el-table-column :label="$t('me.order_number')" prop="orderNo" min-width="100px" />

        <el-table-column :label="$t('me.purchase_time')" prop="orderCreateTime">
          <template slot-scope="scope">
            <span class="buy-time">{{ scope.row.orderCreateTime }}</span>
          </template>
        </el-table-column>

        <el-table-column :label="$t('me.purchase_price')">
          <template #default="scope">
            <span>{{ scope.row.currencyUnit }}{{ scope.row.paymentPriceBig }}</span>
          </template>

        </el-table-column>

        <el-table-column :label="$t('me.purchase_status')" align="center" prop="orderStatus">
          <template v-if="statusList && statusList.length > 0" #default="scope">
            <span class="status-text" :class="statusList[scope.$index].className">{{ statusList[scope.$index].name }}</span>
            <el-button type="text" class="text-button" @click="onClickCheckBtn(scope.row.id)">{{ $t('me.check_order') }}</el-button>
          </template>
        </el-table-column>

        <el-table-column>
          <template v-if="statusList && statusList.length > 0">
            <!-- <div v-if="scope.row.orderStatus === 0" class="operation-button">
              <span class="label" :class="statusList[scope.$index].className">{{ statusList[scope.$index].name }}</span>
            </div> -->
            <!-- <div v-if="scope.row.orderStatus === 2" class="operation-button">
              <span class="label" :class="statusList[scope.$index].className">收货</span>
            </div> -->
            <!-- <el-button v-if="scope.row.orderStatus === 0 || scope.row.orderStatus === 1" type="text" class="text-button none">取消订单</el-button> -->
          </template>
        </el-table-column>
      </el-table>

    </div>
    <Pagination style="margin-bottom:20px" :data="pageData" @current-change="onPageChange" />
  </div>
</template>

<script>
import { reactive, toRefs, computed } from '@vue/composition-api'
import { getDomainPrefix } from '@/utils'
import { useRequest } from '@/utils/request.js'
import Pagination from '@/components/Common/Pagination.vue'

export default {
  name: 'ProfileOrder',
  components: {
    Pagination
  },
  setup(_, { root }) {
    const router = root.$router
    const $jst = root.$jst

    const state = reactive({
      active: 0,

      tabList: [
        {
          name: $jst('me.all_orders'),
          number: 0
        },
        {
          name: $jst('me.to_be_pay'),
          number: 0
        },
        {
          name: $jst('me.to_be_delivery'),
          number: 0
        },
        {
          name: $jst('me.delivering'),
          number: 0
        },
        {
          name: $jst('me.refund'),
          number: 0
        }
      ],

      tableData: [],

      pageData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      status: computed(() => ({
        '0': { name: $jst('me.to_be_pay'), className: 'danger', value: '0' },
        '1': { name: $jst('me.to_be_delivery'), className: 'primary', value: '1' },
        '2': { name: $jst('me.delivering_1'), className: 'success', value: '2' },
        '3': { name: $jst('me.deal_success'), className: 'success', value: '3' },
        '-1': { name: $jst('me.cancelled'), className: 'cancelled', value: '-1' },
        '-2': { name: $jst('me.cancelling'), className: 'cancelled', value: '-2' }
      }))
    })

    useRequest('order/orderNum', {
      data: {
        domainPrefix: getDomainPrefix // process.env.VUE_APP_SHOP_DOMAIN
      },
      isCustom: true,
      onSuccess: (res) => {
        const { data } = res
        buildTabList(data)
      }
    })

    const { fetch: orderList } = useRequest('order/orderList', {
      data: computed(() => ({
        pageNo: state.pageData.pageNo,
        pageSize: 10,
        orderStatus: state.active === 4 ? '-2' : state.active,
        domainPrefix: getDomainPrefix
      })),
      isCustom: true,
      onSuccess: (res) => {
        const { data } = res
        state.tableData = data.records
        state.pageData.total = data.total
      }
    })

    const buildTabList = (numObj) => {
      // const temp = []
      // temp.push({ name: $jst('me.all_orders'), number: numObj.allNum })
      // temp.push({ name: $jst('me.to_be_pay'), number: numObj.stayPaymentNum })
      // temp.push({ name: $jst('me.to_be_delivery'), number: numObj.stayDeliveryNum })
      // temp.push({ name: $jst('me.delivering'), number: numObj.stayReceivingNum })
      // temp.push({ name: $jst('me.refund'), number: numObj.cancellationNum })
      state.tabList = computed(() => (
        [
          {
            name: $jst('me.all_orders'),
            number: numObj.allNum
          },
          {
            name: $jst('me.to_be_pay'),
            number: numObj.stayPaymentNum
          },
          {
            name: $jst('me.to_be_delivery'),
            number: numObj.stayDeliveryNum
          },
          {
            name: $jst('me.delivering'),
            number: numObj.stayReceivingNum
          },
          {
            name: $jst('me.refund'),
            number: numObj.cancellationNum
          }
        ]
      ))
    }

    const statusList = computed(() => {
      return state.tableData.map(current => {
        if (current) {
          return state.status[current.orderStatus]
        }
      })
    })

    const onClickTabItem = (item, index) => {
      state.pageData.pageNo = 1
      state.active = index
      orderList()
    }

    const onClickCheckBtn = (id) => {
      router.push({ name: 'OrderDetail', query: { orderid: id }})
    }

    const onPageChange = (page) => {
      state.pageData.pageNo = page

      orderList()
    }

    return {
      ...toRefs(state),

      onClickTabItem,
      onClickCheckBtn,
      statusList,
      onPageChange
    }
  }
}
</script>

<style lang="scss" scoped>
.order__container {
  height: 100%;
  padding: 0 40px;
  border-radius: 6px;
  background: #fff;
}

.order__header {
  height: 60px;
  // padding-left: 42px;
  border-bottom: 1px solid #E2E2E2;
  margin-bottom: 8px;
  line-height: 59px;

  .list {
    @include clearfix;

    .item {
      float: left;
      margin-right: 41px;
      font-size: 14px;

      .link {
        display: block;
        color: #666666;
        // padding-left: 10px;
        font-weight: bolder;

        &.active {
          font-weight: bolder;
          color: #0049AC;
          border-bottom: 2px solid #0049AC;
        }
      }

      &.small {
        margin-right: 38px;
      }
    }
  }
}

.order__body {
  padding-bottom: 19px;

  .label {
    display: inline-block;
    padding: 0 6px;
    border-radius: 6px;
    line-height: 19px;
    color: #fff;

    &.primary {
      background: #0049AC;
    }

    &.success {
      background: #ACA600;
    }

    &.danger {
      background: #FF2900;
      // color: #FFC600;
    }

    &.cancelled {
      background: #9F9F9F;
    }
  }

  .status-text {
    display: block;
    padding: 0 6px;
    border-radius: 6px;
    line-height: 19px;
    color: #fff;

    &.primary {
      // background: #0049AC;
      color: #ABABAB;
    }

    &.success {
      // background: #ACA600;
      color: #ABABAB;
    }

    &.danger {
      // background: #FF2900;
      color: #FFC600;
    }

    &.cancelled {
      color: #ABABAB;
      // background: #9F9F9F;
    }
  }

  .text-button {
    display: block;
    width: 100%;
    font-weight: normal;
    text-decoration: underline;

    &.none {
      text-decoration: none;
      color: #ABABAB;
    }
  }

  .operation-button {
    text-align: center;

    .label {
      width: 80px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      cursor: pointer;
    }
  }

  ::v-deep .header-color {
    th {
      padding: 0;
      background: #F2F2F2;
    }

    .cell {
      border-radius: 4px;
      font-weight: normal;
      font-size: 16px;
      line-height: 40px;

      color: #ABABAB;
    }
  }

  ::v-deep .body-color {

    .cell {
      line-height: 36px;
      color: #212121;
    }
  }

  .buy-time {
    color: #ABABAB;
  }
}
</style>
